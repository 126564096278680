import * as React from "react";

import { Fade, Slide } from "react-awesome-reveal";
import { Parallax } from "react-scroll-parallax";
import { Element, scroller } from "react-scroll";
import { Icon } from "@utdanningsdirektoratet/icon";
import { onKeyDown } from "@utdanningsdirektoratet/utils/uu";
import { u } from "@utdanningsdirektoratet/styles";
import { Carousel } from "@utdanningsdirektoratet/carousel";
import { Lightbox } from "@utdanningsdirektoratet/lightbox";
import { Grid, Reverse } from "@utdanningsdirektoratet/grid";

import start from "images/screenshots/start-min.png";
import uttalelse from "images/screenshots/uttalelse-min.png";
import api from "images/screenshots/api-min.png";
import publisering from "images/screenshots/publisering-min.png";
import dsbpublisering from "images/screenshots/dsbpublisering-min.png";
import miljodirpublisering from "images/screenshots/miljodirpublisering-min.png";

import skjema from "images/screenshots/skjema.png";
import skjemagif from "images/screenshots/skjema.webm";
import rapport from "images/screenshots/rapport.png";
import rapportgif from "images/screenshots/rapport.webm";
import Customers from "./components/Customers";
import ParallaxCache from "./components/ParallaxCache";
import Image from "./components/Image";
import Box from "./components/Box";
import Slice from "./components/Slice";
import Footer from "./components/Footer";
import Header from "./components/Header";
import styles from "./App.module.less";

const scrollToElement = "scrollToElement";
const breakpoint = 980;
const mailto = "mailto:Mari.Hidle.Simonsen@udir.no?subject=Jeg er interessert i høringssystemet";

const App: React.FC = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const disableParallax = width < breakpoint;

  const scrollToNext = () => {
    scroller.scrollTo(scrollToElement, { smooth: true });
  };

  React.useEffect(() => {
    const setWindowWidth = () => setWidth(window.innerWidth);
    window.addEventListener("resize", setWindowWidth);

    return () => {
      window.removeEventListener("resize", setWindowWidth);
    };
  }, []);

  return (
    <main className={styles.app}>
      <Header mailto={mailto} />
      <Slice className={styles.appLanding}>
        <Grid size="12-12" lg="6-12">
          <h1 className={styles.appHeadingTitle}>
            <span>Et</span> <span>system</span> <span>for</span> <span>offentlige</span> <span>høringer</span>
          </h1>
          <p className={styles.appHeadingSubtitle}>
            Med <strong>høring</strong> kan du publisere og få innspill på høringer og analysere innspillene i ett system.
          </p>
        </Grid>
        <Grid size="12-12" lg="6-12">
          <Image className={styles.appHeadingImage} image={start} alt="Rapport skjermbilde" />
        </Grid>
        <div className={styles.appArrow}>
          <div
            tabIndex={0}
            role="button"
            aria-label="Gå videre til neste seksjon"
            onClick={scrollToNext}
            onKeyDown={onKeyDown(scrollToNext)}
          >
            <Icon icon="arrow down" type="large" />
          </div>
        </div>
      </Slice>
      <Slice>
        <Element name={scrollToElement} className={styles.appMarker} />
        <Fade triggerOnce>
          <Slide direction="right" triggerOnce>
            <Reverse>
              <Grid size="12-12" lg="8-12">
                <Box color="palegreen">
                  <strong>Høring</strong> hjelper med å ivareta mer demokratiske høringsprosesser. Systemet legger til rette for effektiv
                  behandling– og strukturering av innspill, slik at sakene på høring skal bli så godt belyst som mulig.
                </Box>
              </Grid>
            </Reverse>
          </Slide>
        </Fade>
      </Slice>
      <Slice>
        <div>
          <Grid size="12-12" lg="3-12">
            <Parallax translateY={[50, -50]} disabled={disableParallax}>
              <Box color="whitepalegreen" medium>
                Mer demokratiske høringsprosesser
                <Box color="darkgreen" medium>
                  Senke terskel for å gi innspill og engasjere et bredere utvalg av interessenter, for å få et best mulig
                  beslutningsgrunnlag.
                </Box>
              </Box>
            </Parallax>
          </Grid>
          <Grid size="12-12" lg="3-12" lgOffset="1-12">
            <Parallax translateY={[150, -150]} disabled={disableParallax}>
              <Box color="black" medium>
                Økt digitalisering
                <Box color="white" medium>
                  Heldigitalisere arbeidet med høringer med mål om at innspill kan behandles som data gjennom hele prosessen, fra innsendt
                  svar til offentlig publisering.
                </Box>
              </Box>
            </Parallax>
          </Grid>
          <Grid size="12-12" lg="3-12" lgOffset="1-12" className={u.marginBottom2}>
            <Parallax translateY={[250, -250]} disabled={disableParallax}>
              <Box color="palegreen" medium>
                Mer effektiv gjennomføring av høringer
                <Box color="whitedarkgreen" medium>
                  Redusere tid brukt på behandling og analyse av uttalelser.
                </Box>
              </Box>
            </Parallax>
          </Grid>
        </div>
      </Slice>
      <Slice>
        <div>
          <Fade triggerOnce>
            <>
              <Grid size="12-12" lg="6-12">
                <Slide direction="left" triggerOnce>
                  <Lightbox className={styles.appStepsImage}>
                    <video autoPlay loop muted playsInline poster={skjema}>
                      <source type="video/webm" src={skjemagif} />
                      <img src={skjema} alt="Skjemabygger" />
                    </video>
                  </Lightbox>
                </Slide>
              </Grid>
              <Grid size="12-12" lg="5-12" lgOffset="1-12">
                <Slide direction="right" triggerOnce>
                  <h2 className={styles.appStepsHeading}>Sett opp og publiser en høring raskt og effektivt</h2>
                  <p className={styles.appStepsText}>
                    Skjemabyggeren gjør det enkelt å sette opp en høring. Legg til spørsmål med svaralternativer eller generelle
                    kommentarer.
                  </p>
                  <ul className={styles.appStepsList}>
                    <li className={styles.appStepsStep}>
                      <Icon icon="check" placement="before" />
                      Jobb i Word &mdash; og importer innholdet rett inn i skjemabyggeren
                    </li>
                    <li className={styles.appStepsStep}>
                      <Icon icon="check" placement="before" />
                      Benytt ferdige komponenter
                    </li>
                    <li className={styles.appStepsStep}>
                      <Icon icon="check" placement="before" />
                      Sett en frist og høringen stenges automatisk når fristen utgår
                    </li>
                  </ul>
                </Slide>
              </Grid>
            </>
          </Fade>
        </div>
      </Slice>
      <Slice color="palegreen">
        <Fade triggerOnce>
          <Reverse>
            <Grid size="12-12" lg="6-12" lgOffset="1-12">
              <Slide direction="right" triggerOnce>
                <Lightbox className={styles.appStepsImage}>
                  <Image image={uttalelse} alt="Uttalelse skjermbilde" />
                </Lightbox>
              </Slide>
            </Grid>
            <Grid size="12-12" lg="5-12">
              <Slide direction="left" triggerOnce>
                <h2 className={styles.appStepsHeading}>Samle og behandle alle uttalelsene på ett sted</h2>
                <p className={styles.appStepsText}>
                  Bla gjennom uttalelsene for å få et bilde av hva hver enkelt instans mener om høringsforslaget.
                </p>
                <ul className={styles.appStepsList}>
                  <li className={styles.appStepsStep}>
                    <Icon icon="check" placement="before" />
                    Gå gjennom uttalelsene raskt og enkelt
                  </li>
                  <li className={styles.appStepsStep}>
                    <Icon icon="check" placement="before" />
                    Sorter uttalelsene basert på målgrupper
                  </li>
                </ul>
              </Slide>
            </Grid>
          </Reverse>
        </Fade>
      </Slice>
      <Slice>
        <div>
          <Fade triggerOnce className="animation">
            <Grid size="12-12" lg="6-12">
              <Slide direction="left" triggerOnce>
                <Lightbox className={styles.appStepsImage}>
                  <video autoPlay loop muted playsInline poster={rapport}>
                    <source type="video/webm" src={rapportgif} />
                    <img src={rapport} alt="Rapport" />
                  </video>
                </Lightbox>
              </Slide>
            </Grid>
            <Grid size="12-12" lg="5-12" lgOffset="1-12">
              <Slide direction="right" triggerOnce>
                <h2 className={styles.appStepsHeading}>Analyser uttalelsene som data</h2>
                <p className={styles.appStepsText}>
                  Få kontinuerlig statistikk over reaksjonene på de ulike temaene og rapporter med grafer og figurer.
                </p>
                <ul className={styles.appStepsList}>
                  <li className={styles.appStepsStep}>
                    <Icon icon="check" placement="before" />
                    Se samlet oversikt over innspillene på hvert enkelt spørsmål
                  </li>
                  <li className={styles.appStepsStep}>
                    <Icon icon="check" placement="before" />
                    Filtrer slik at du kan gå dypere i trendene som er kommet frem
                  </li>
                  <li className={styles.appStepsStep}>
                    <Icon icon="check" placement="before" />
                    Last ned rapport med alle uttalelser
                  </li>
                </ul>
              </Slide>
            </Grid>
          </Fade>
        </div>
      </Slice>
      <Slice>
        <Fade triggerOnce>
          <Slide direction="up" triggerOnce>
            <Grid size="12-12" lg="8-12" lgOffset="2-12">
              <p className={styles.appCustomersText}>
                Høring er i kontinuerlig utvikling. Systemet utvikles og forbedres gradvis etter saksbehandlernes initiativ og behov. I dag
                er det fem offentlige virksomheter som bruker systemet.
              </p>
            </Grid>
            <Customers className={u.marginTop6} />
          </Slide>
        </Fade>
      </Slice>
      <Slice color="palegreen">
        <div>
          <Fade triggerOnce>
            <>
              <Grid size="12-12" lg="4-12">
                <Slide direction="left" triggerOnce>
                  <h2 className={styles.appStepsHeading}>Publiser høringer på din plattform</h2>
                  <p className={styles.appStepsText}>
                    Høringsløsningen kjører i skyen og tilbyr et API som kan brukes til å enkelt integrere publisering av høringene på egne
                    nettsider.
                  </p>
                  <p>
                    <a className={styles.appButton} href={mailto} target="_blank" rel="noopener noreferrer">
                      Kontakt oss for en demo
                    </a>
                  </p>
                </Slide>
              </Grid>
              <Grid size="12-12" lg="7-12" lgOffset="1-12">
                <Slide direction="right" triggerOnce>
                  <Carousel className={styles.appCarousel} backButton={false}>
                    <Lightbox className={styles.appStepsImage}>
                      <Image image={api} alt="API skjermbilde" />
                    </Lightbox>
                    <Lightbox className={styles.appStepsImage}>
                      <Image image={publisering} alt="Publiserte høringer udir skjermbilde" />
                    </Lightbox>
                    <Lightbox className={styles.appStepsImage}>
                      <Image image={dsbpublisering} alt="Publiserte høringer dsb skjermbilde" />
                    </Lightbox>
                    <Lightbox className={styles.appStepsImage}>
                      <Image image={miljodirpublisering} alt="Publiserte høringer miljødirektoratet skjermbilde" />
                    </Lightbox>
                  </Carousel>
                </Slide>
              </Grid>
            </>
          </Fade>
        </div>
      </Slice>
      <Footer />
      <ParallaxCache />
    </main>
  );
};

export default App;
