import * as React from "react";
import { cls } from "@utdanningsdirektoratet/utils/cls";
import { Grid } from "@utdanningsdirektoratet/grid";

import udir from "images/udir-min.png";
import dsb from "images/dsb-min.png";
import miljodirektoratet from "images/miljodirektoratet-min.png";
import forskningsradet from "images/forskningsradet-min.png";
import dibk from "images/dibk-min.png";
import Image from "../Image";
import styles from "./Customers.module.less";

type CustomersProps = {
  className?: string;
};

const Customers: React.FC<CustomersProps> = ({ className }) => {
  const customerClass = cls({
    [styles.customersCustomer]: true,
  });

  return (
    <div className={className}>
      <Grid size="12-12" md="6-12" lg="3-12" className={customerClass}>
        <a href="https://www.udir.no/om-udir/hoyringar/" target="_blank" rel="noopener noreferrer">
          <Image className={styles.customersCustomerLogo} image={udir} alt="Utdanningsdirektoratet logo" />
        </a>
      </Grid>
      <Grid size="12-12" md="6-12" lg="3-12" className={customerClass}>
        <a href="https://www.dsb.no/hoeringer-og-konsekvensutredninger/" target="_blank" rel="noopener noreferrer">
          <Image className={styles.customersCustomerLogo} image={dsb} alt="DSB logo" />
        </a>
      </Grid>
      <Grid size="12-12" md="6-12" lg="3-12" className={customerClass}>
        <a href="https://www.miljodirektoratet.no/hoeringer/" target="_blank" rel="noopener noreferrer">
          <Image className={styles.customersCustomerLogo} image={miljodirektoratet} alt="Miljødirektoratet logo" />
        </a>
      </Grid>
      <Grid size="12-12" md="6-12" lg="3-12" className={customerClass}>
        <a href="https://www.forskningsradet.no/" target="_blank" rel="noopener noreferrer">
          <Image className={styles.customersCustomerLogo} image={forskningsradet} alt="Forskningsrådet logo" />
        </a>
      </Grid>
      <Grid lgOffset="5-12" size="12-12" md="6-12" lg="3-12" className={customerClass}>
        <a href="https://www.dibk.no/" target="_blank" rel="noopener noreferrer">
          <Image
            className={styles.customersCustomerLogo}
            style={{ height: "100px", width: "auto" }}
            image={dibk}
            alt="Direktoratet for byggkvalitet logo"
          />
        </a>
      </Grid>
    </div>
  );
};

export default Customers;
